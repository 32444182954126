import { ref } from 'vue';
import axios from 'axios';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

export function useCloudStorage() {
    const s3Config = ref(null);

    // Method to set Authorization header dynamically
    const setAuthorizationHeader = () => {
        const bearerToken = localStorage.getItem('bearer_token');
        if (bearerToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        } else {
            delete axios.defaults.headers.common['Authorization']; // Remove header if no token
        }
    };

    const fetchCloudStorageData = async (organizationId = 0) => {
        setAuthorizationHeader(); // Set Authorization before the request
        try {
            const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/v1/token`, {
                params: {
                    organization_id: organizationId,
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            s3Config.value = response.data.data;
        } catch (error) {
            console.error("Failed to fetch S3 config:", error);
            throw error;
        }
    };

    const uploadFile = async (file, fileName, onProgress = null) => {
        if (!file) {
            console.error("No file provided!");
            return;
        }
        if (!s3Config.value) {
            console.error("Config not available! Please fetch the config first.");
            throw new Error("Config not available! Please fetch the config first.");
        }

        let s3Client = new S3Client({
            endpoint: s3Config.value.endpoint,
            region: s3Config.value.region,
            credentials: {
              accessKeyId: s3Config.value.token,
              secretAccessKey: s3Config.value.secret
            }
            
        });

        const upload = new Upload({
            client: s3Client,
            params: {
                Bucket: s3Config.value.bucket,
                Key: fileName,
                Body: file,
            },
            queueSize: 4,
            partSize: 5 * 1024 * 1024,
            leavePartsOnError: false,
        });

        if (onProgress) {
            upload.on("httpUploadProgress", (progress) => {
                const percentage = Math.round((progress.loaded / progress.total) * 100);
                onProgress(percentage, progress);
            });
        }
            
        try {
            return upload.done();
        } catch (error) {
            console.error("File upload failed:", error);
            throw error;
        }
    };

    return {
        s3Config,
        fetchCloudStorageData,
        uploadFile,
    };
}