import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import { createAppRouter } from './router'
import store from './store/index.js';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import * as Sentry from "@sentry/vue";

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ObserveVisibility } from 'vue-observe-visibility'

// SurveyJS
import { surveyPlugin } from "survey-vue3-ui";
import { surveyCreatorPlugin } from "survey-creator-vue";

library.add(fas, far, fab)

async function boot() {
    const app = createApp(App)

    app.directive('observe-visibility', {
        beforeMount: (el, binding, vnode) => {
            vnode.context = binding.instance;
            ObserveVisibility.bind(el, binding, vnode);
        },
        update: ObserveVisibility.update,
        unmounted: ObserveVisibility.unbind,
    });

    app.config.globalProperties.$cdnUrl = import.meta.env.VITE_CDN_BASE_URL;
    app.config.globalProperties.$token = import.meta.env.VITE_TOKEN;
    app.config.globalProperties.$publicUrl = import.meta.env.VITE_PUBLIC_URL;
    app.config.globalProperties.$adsPlayerType = {
        bottomPlayer: 5,
        rightTopPlayer: 6,
        rightBottomPlayer: 7,
    };

    app.config.globalProperties.$livePlayerLayoutSideContents = {
        notes: 1,
        checkIn: 2,
        chat: 4
    };

    app.config.globalProperties.$userRole = {
        SUPER: 1,
        ORGANIZATION: 2,
        ORGANIZATION_USER: 3
    };

    app.config.globalProperties.$filters = {
        formatDate(dateInput) {
            if(dateInput){
                let dateNew = dateInput;
                if (dateInput.includes(' ')) {
                    dateNew = dateInput.split(' ')[0];
                }
                if (dateInput.includes('T')) {
                    dateNew = dateInput.split('T')[0];
                }
                dateNew = dateNew.split('-');
                let year = dateNew.shift();
                dateNew.push(year);
                dateNew = dateNew.join('-');        
                return dateNew;
            }else{
                return '-';
            }
        }
    };

    app.config.globalProperties.$getCategoryPath = (url_path, length, isEmbedded) => {
        if (!isEmbedded) {
            if (length == 2) {
                return { name: '.categoriesTwo', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1] } };
            }
            if (length == 3) {
                return { name: '.categoriesThree', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1], categoryThree: url_path.split('/')[2] } };
            }
            if (length == 4) {
                return { name: '.categoriesFour', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1], categoryThree: url_path.split('/')[2], categoryFour: url_path.split('/')[3] } }
            }
            return { name: '.categoriesOne', params: { categoryOne: url_path } };
        }
        if (isEmbedded) {
            if (length == 2) {
                return { name: '.categoriesTwo-embed', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1] } };
            }
            if (length == 3) {
                return { name: '.categoriesThree-embed', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1], categoryThree: url_path.split('/')[2] } };
            }
            if (length == 4) {
                return { name: '.categoriesFour-embed', params: { categoryOne: url_path.split('/')[0], categoryTwo: url_path.split('/')[1], categoryThree: url_path.split('/')[2], categoryFour: url_path.split('/')[3] } }
            }
            return { name: '.categoriesOne-embed', params: { categoryOne: url_path } };
        }
    };

    app.config.globalProperties.$getMediaPath = (path, slug, isEmbedded) => {
        let arr = path.split('/')
        arr.pop('');
        let res = arr.join('/');
        if (!isEmbedded) {
            return { name: '.mediaPlayer1', params: { categoryOne: res, mediaSlug: slug } }
        } else {
            return { name: '.mediaPlayer1-embed', params: { categoryOne: res, mediaSlug: slug } }
        }
    };
    app.config.globalProperties.$getMediaPath2 = (path, slug, isEmbedded) => {
        
        let arr = path.split('/')
        arr.pop('');
        let res = arr.join('/');
        // console.log(path);
        // console.log(res);
        if (!isEmbedded) {
            return { name: '.mediaPlayer1', params: { categoryOne: path, mediaSlug: slug } }
        } else {
            return { name: '.mediaPlayer1-embed', params: { categoryOne: path, mediaSlug: slug } }
        }
    };

    app.config.globalProperties.$setWidthAndHeight = (width, procent, limit) => {
        let height;
        if (width <= limit) {
            height = Math.floor(width * procent);
            return {
                width,
                height,
            };
        } else {
            height = Math.floor(limit * procent);
            return {
                width: limit,
                height,
            };
        }

    };

    app.use(surveyPlugin);
    app.use(surveyCreatorPlugin);

    app.use(store);

    await store.dispatch('fetchRoutingDomains');

    const router = createAppRouter(store.state.currentCustomDomain);
    app.use(router);

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
            Sentry.browserProfilingIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "http://localhost:4173", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        // Profiling
        profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    });

    app.use(createMetaManager());
    app.use(metaPlugin);

    app.component('font-awesome-icon', FontAwesomeIcon)

    app.mount('#app')
}

boot();